<template>
	<li
		class="nav-item dropdown"
		v-if="
			getCapabilityAble('accounts') ||
				getCapabilityAble('users') ||
				getCapabilityAble('sales') ||
				getCapabilityAble('payment_links')
		"
	>
		<a
			id="dropdownSubMenu1"
			href="#"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			class="nav-link dropdown-toggle"
			>Account</a
		>
		<ul
			aria-labelledby="dropdownSubMenu1"
			class="dropdown-menu border-0 shadow"
		>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('accounts')"
					to="/accounts"
					>Accounts</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('users')"
					to="/users"
					>Users</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('sales')"
					to="/sales"
					>Sales</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('payment_links')"
					to="/payment_links"
					>Payment Link</router-link
				>
			</li>
		</ul>
	</li>
	<li
		class="nav-item dropdown"
		v-if="
			getCapabilityAble('gps') ||
				getCapabilityAble('sim_cards') ||
				getCapabilityAble('vehicles') ||
				getCapabilityAble('drivers') ||
				getCapabilityAble('ibuttons') ||
				getCapabilityAble('gps_cameras') ||
				getCapabilityAble('ceiba_devices')
		"
	>
		<a
			id="dropdownSubMenu2"
			href="#"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			class="nav-link dropdown-toggle"
			>Asset</a
		>
		<ul
			aria-labelledby="dropdownSubMenu2"
			class="dropdown-menu border-0 shadow"
		>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('gps')"
					to="/gps"
					>GPS</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('sim_cards')"
					to="/sim_cards"
					>Sim Cards</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('vehicles')"
					to="/vehicles"
					>Vehicles</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('drivers')"
					to="/drivers"
					>Drivers</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('ibuttons')"
					to="/ibuttons"
					>iButton</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('gps_cameras')"
					to="/gps_cameras"
					>GPS Camera</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('ceiba_devices')"
					to="/ceiba_devices"
					>Ceiba Device</router-link
				>
			</li>
		</ul>
	</li>
	<li
		class="nav-item dropdown"
		v-if="
			getCapabilityAble('stops') ||
				getCapabilityAble('routes') ||
				getCapabilityAble('sub_routes') ||
				getCapabilityAble('alerts') ||
				getCapabilityAble('send_commands') ||
				getCapabilityAble('telegram_settings') ||
				getCapabilityAble('route_targets')
		"
	>
		<a
			id="dropdownSubMenu3"
			href="#"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			class="nav-link dropdown-toggle"
			>Configuration</a
		>
		<ul
			aria-labelledby="dropdownSubMenu3"
			class="dropdown-menu border-0 shadow"
		>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('stops')"
					to="/stops"
					>Stops</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('routes')"
					to="/routes"
					>Routes</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('sub_routes')"
					to="/sub_routes"
					>Sub Routes</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('alerts')"
					to="/alerts"
					>Custom Alerts</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('send_commands')"
					to="/gps_commands"
					>GPS Command</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('telegram_settings')"
					to="/telegram_settings"
					>Telegram Settings</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('route_targets')"
					to="/route_targets"
					>Routes Target</router-link
				>
			</li>
		</ul>
	</li>
	<li
		class="nav-item dropdown"
		v-if="
			getCapabilityAble('work_orders') ||
				getCapabilityAble('maintenance_schedules') ||
				getCapabilityAble('maintenance_history_by_vehicle') ||
				getCapabilityAble('part_mileages') ||
				getCapabilityAble('inventories') ||
				getCapabilityAble('inventory_history')
		"
	>
		<a
			id="dropdownSubMenu4"
			href="#"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			class="nav-link dropdown-toggle"
			>Maintenance</a
		>
		<ul
			aria-labelledby="dropdownSubMenu4"
			class="dropdown-menu border-0 shadow"
		>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('work_orders')"
					to="/work_orders"
					>Work Order</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('maintenance_schedules')"
					to="/maintenance_reminders"
					>Maintenance Reminders</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('maintenance_schedules')"
					to="/maintenance_schedules"
					>Maintenance Schedules</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('maintenance_history_by_vehicle')"
					to="/maintenance_history_by_vehicle"
					>Maintenance History by Vehicle</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('part_mileages')"
					to="/part_mileages"
					>Part Mileages</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('inventories')"
					to="/inventories"
					>Inventories</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('inventory_history')"
					to="/inventory_histories"
					>History Inventory</router-link
				>
			</li>
		</ul>
	</li>
	<li
		class="nav-item dropdown"
		v-if="
			getCapabilityAble('checklist') ||
				getCapabilityAble('checklist_models') ||
				getCapabilityAble('checklist_items') ||
				getCapabilityAble('checklist_users')
		"
	>
		<a
			id="dropdownSubMenu8"
			href="#"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			class="nav-link dropdown-toggle"
			>Digital Checklist</a
		>
		<ul
			aria-labelledby="dropdownSubMenu8"
			class="dropdown-menu border-0 shadow"
		>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('checklist')"
					to="/checklists"
					>Checklists</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('checklist_models')"
					to="/checklist_models"
					>Checklist Model</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('checklist_items')"
					to="/checklist_items"
					>Checklist Item</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('checklist_users')"
					to="/checklist_users"
					>Checklist User</router-link
				>
			</li>
		</ul>
	</li>
	<li
		class="nav-item dropdown"
		v-if="
			getCapabilityAble('trips') ||
				getCapabilityAble('spdas') ||
				getCapabilityAble('road_warrants') ||
				getCapabilityAble('add_trip') ||
				getCapabilityAble('trip_schedules') ||
				getCapabilityAble('deliveries') ||
				getCapabilityAble('costs') ||
				getCapabilityAble('vehicle_routes') ||
				getCapabilityAble('case_incidents')
		"
	>
		<a
			id="dropdownSubMenu5"
			href="#"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			class="nav-link dropdown-toggle"
			>Operation</a
		>
		<ul
			aria-labelledby="dropdownSubMenu5"
			class="dropdown-menu border-0 shadow"
		>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('trips')"
					to="/trips"
					>Trip</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('spdas')"
					to="/spdas"
					>SPDA</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('road_warrants')"
					to="/road_warrants"
					>Road Warrant</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('add_trip') || getCapabilityAble('add_spda')"
					to="/upload_trips"
					>Upload Trip</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('trip_schedules')"
					to="/trip_schedules"
					>Trip Schedule</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('deliveries')"
					to="/deliveries"
					>Deliveries</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('costs')"
					to="/costs"
					>Operational Cost</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('vehicle_routes')"
					to="/vehicle_routes"
					>Vehicle Routes</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('case_incidents')"
					to="/case_incidents"
					>Case Incidents</router-link
				>
			</li>
		</ul>
	</li>
	<li
		class="nav-item dropdown"
		v-if="
			getCapabilityAble('live_maps') ||
				getCapabilityAble('monitor_schematics') ||
				getCapabilityAble('monitor_vehicles') ||
				getCapabilityAble('monitor_route_vehicles') ||
				getCapabilityAble('monitor_custom_event_alerts') ||
				getCapabilityAble('vehicle_sharings') ||
				getCapabilityAble('live_video_streamings') ||
				getCapabilityAble('live_video_alerts')
		"
	>
		<a
			id="dropdownSubMenu6"
			href="#"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			class="nav-link dropdown-toggle"
			>Monitoring</a
		>
		<ul
			aria-labelledby="dropdownSubMenu6"
			class="dropdown-menu border-0 shadow"
		>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('live_maps')"
					to="/live_maps"
					>Live Map</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('monitor_schematics')"
					to="/monitor_schematics"
					>Monitoring Schematic</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('monitor_vehicles')"
					to="/monitor_vehicles"
					>Monitoring Vehicle</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('monitor_route_vehicles')"
					to="/monitor_route_vehicles"
					>Monitoring Route Vehicle</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('monitor_custom_event_alerts')"
					to="/monitor_custom_event_alerts"
					>Monitoring Custom Event Alerts</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('vehicle_sharings')"
					to="/vehicle_sharings"
					>Vehicle Sharings</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('live_video_streamings')"
					to="/live_video_streamings"
					>Live Video Streamings</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('live_video_alerts')"
					to="/live_video_alerts"
					>Monitoring Video Alerts</router-link
				>
			</li>
		</ul>
	</li>
	<li
		class="nav-item dropdown"
		v-if="
			getCapabilityAble('report_operational_vehicles') ||
				getCapabilityAble('report_location_histories') ||
				getCapabilityAble('report_history_vehicles') ||
				getCapabilityAble('report_journey_vehicles') ||
				getCapabilityAble('report_driver_journey') ||
				getCapabilityAble('report_driver_ranking') ||
				getCapabilityAble('report_stop_bus_time') ||
				getCapabilityAble('report_checkpoint_vehicles') ||
				getCapabilityAble('report_punctuality') ||
				getCapabilityAble('report_custom_alerts') ||
				getCapabilityAble('report_expired_licenses') ||
				getCapabilityAble('report_history_sensors') ||
				getCapabilityAble('report_graph_sensors') ||
				getCapabilityAble('report_cameras') ||
				getCapabilityAble('report_history_video_alerts') ||
				getCapabilityAble('report_passenger_flow') ||
				getCapabilityAble('report_route_target') ||
				getCapabilityAble('report_generate') ||
				getCapabilityAble('insert_position') ||
				getCapabilityAble('report_login_log') ||
				getCapabilityAble('report_driver_login') ||
				getCapabilityAble('report_geofence') ||
				getCapabilityAble('report_geofence_summary_km') ||
				getCapabilityAble('report_history_journey') ||
				getCapabilityAble('report_sync_geofence_summary_km') ||
				getCapabilityAble('adjust_geofence_summary_km')
		"
	>
		<a
			id="dropdownSubMenu7"
			href="#"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			class="nav-link dropdown-toggle"
			>Reports</a
		>
		<ul
			aria-labelledby="dropdownSubMenu7"
			class="dropdown-menu border-0 shadow"
		>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_operational_vehicles')"
					to="/report_operational_vehicles"
					>Report Operational Vehicle</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_location_histories')"
					to="/report_location_histories"
					>Report Location History</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_history_vehicles')"
					to="/report_history_vehicles"
					>Report History Vehicle</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_journey_vehicles')"
					to="/report_journey_vehicles"
					>Report Journey Vehicle</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_driver_journey')"
					to="/report_driver_journey"
					>Report Journey Driver</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_driver_ranking')"
					to="/report_driver_ranking"
					>Report Driver Ranking</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_stop_bus_time')"
					to="/report_stop_bus_time"
					>Report Stop Bus Time</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_checkpoint_vehicles')"
					to="/report_checkpoint_vehicles"
					>Report Checkpoint Vehicle</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_punctuality')"
					to="/report_punctuality"
					>Report Trip Punctuality</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_custom_alerts')"
					to="/report_custom_alerts"
					>Report Custom Alerts</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_expired_licenses')"
					to="/report_expired_licenses"
					>Report Expiring Licenses</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_history_sensors')"
					to="/report_history_sensors"
					>Report History Sensor</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_graph_sensors')"
					to="/report_graph_sensors"
					>Report Graph Sensor</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_cameras')"
					to="/report_cameras"
					>Report Camera</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_history_video_alerts')"
					to="/report_history_video_alerts"
					>Report History Video Alert</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_passenger_flow')"
					to="/report_passenger_flow"
					>Report Passenger Flow</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_route_target')"
					to="/report_route_target"
					>Report Route Target</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_generate')"
					to="/report_generate"
					>Report Generate</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('insert_position')"
					to="/insert_position"
					>Insert Position GPS</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_login_log')"
					to="/report_login_log"
					>Report Login Log</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_driver_login')"
					to="/report_driver_login"
					>Report Driver Login</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_geofence')"
					to="/report_geofence"
					>Report Geofence</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_geofence_summary_km')"
					to="/report_geofence_summary_km"
					>Report Geofence Summary by KM</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_sync_geofence_summary_km')"
					to="/report_sync_geofence_summary_km"
					>Sync Geofence Summary by KM</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('adjust_geofence_summary_km')"
					to="/adjust_geofence_summary_km"
					>Adjust Geofence Summary KM</router-link
				>
			</li>
			<li>
				<router-link
					:class="'dropdown-item'"
					v-if="getCapabilityAble('report_history_journey')"
					to="/report_history_journey"
					>Journey Vehicle History</router-link
				>
			</li>
		</ul>
	</li>
	<li
		v-if="
			getCapabilityAble('sensors') ||
				getCapabilityAble('parts') ||
				getCapabilityAble('ceiba_servers')
		"
		class="nav-item dropdown"
	>
		<a
			id="dropdownSubMenu8"
			href="#"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			class="nav-link dropdown-toggle"
			>Master Settings</a
		>
		<ul
			aria-labelledby="dropdownSubMenu8"
			class="dropdown-menu border-0 shadow"
		>
			<li v-if="getCapabilityAble('sensors')">
				<router-link :class="'dropdown-item'" to="/sensors"
					>Sensors</router-link
				>
			</li>
			<li v-if="getCapabilityAble('parts')">
				<router-link :class="'dropdown-item'" to="/parts">Parts</router-link>
			</li>
			<li v-if="getCapabilityAble('ceiba_servers')">
				<router-link :class="'dropdown-item'" to="/ceiba_servers"
					>Ceiba Server</router-link
				>
			</li>
		</ul>
	</li>
</template>

<script>
	import { mapState } from "vuex";
	import { getCapability } from "../_helpers";
	export default {
		computed: {
			...mapState("users", ["user"]),
		},
		methods: {
			getCapabilityAble(action) {
				return getCapability(action, this.user);
			},
		},
	};
</script>
