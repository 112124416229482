<template>
  <nav class="main-header navbar navbar-expand-md navbar-dark">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand">
        <img :src="((user && user.account && user.account.pic_url) ? user.account.pic_url : logo)" alt="Logo" class="brand-image">
      </router-link>

      <button class="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse order-3" id="navbarCollapse">
        <ul class="navbar-nav">
          <Navbar/>
        </ul>
      </div>
      <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="fas fa-th-large"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <router-link to="/profile" class="dropdown-item">
              <!-- Message Start -->
              <div class="media">
                <div class="media-body text-center">
                  <h3 class="dropdown-item-title">
                    {{user ? user.name : ''}}
                  </h3>
                  <p class="text-sm">{{user ? user.email : ''}}</p>
                  <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i>{{user ? moment(user.last_login) : ''}}</p>
                </div>
              </div>
              <!-- Message End -->
            </router-link>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0)" class="dropdown-item dropdown-footer text-danger"
              @click="handleLogout">
              Sign out
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Navbar from './Navbar.vue'
  export default {
    computed: {
        ...mapState('users', ['user'])
    },
    data(){
      return {
        logo: require('../assets/logo.png')
      }
    },
    methods: {
      ...mapActions({
          handleLogout: 'users/logout'
      }),
      moment: function (val) {
        return moment(val).format("LLL");
      },
    },
    components:{
      Navbar,
    },
  }
</script>
